import UsersModule from '@/store/modules/users/UsersModule';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import Validators from './Validators';

import { InputModel, InputType } from '@/models/inputs';
import { required } from '@/services/validators';
import and from '@/services/validators/combiners/and';
import email from '@/services/validators/email';
import between from '@/services/validators/between';
import xor from '@/services/validators/combiners/xor';
import empty from '@/services/validators/empty';
import onlyDigits from '@/services/validators/convertors/onlyDigits';
import UserInfo, { UserAvatar } from '@/models/dto/responses/user.response.type';

@Component({})
export default class UserFields extends mixins(Validators) {
  models: { [key: string]: string | UserAvatar } = {};
  hasChange: { [key: string]: boolean } = {};

  get fields(): Array<InputModel> {
    return [
    
    ];
  }

  get id(): number {
    return parseInt(this.$route.params.id);
  }

  get user(): UserInfo {
    return UsersModule.getById(this.id);
  }
  
  get fullName(): string {
    return `${this.user.first_name} ${this.user.lastName}`;
  }

  get avatar(): string {
    return this.user.avatar;
  }

  get canEdit() {
    const currentUser = UsersModule.currentUser;
    const isAdmin = currentUser.isAdmin;
    const isCurrentUser = currentUser.id === this.id;

    return isCurrentUser || isAdmin;
  }

  public setFieldValue(fieldName: string, value: string | UserAvatar) {
    this.models[fieldName] = value;
    this.hasChange[fieldName] = true;
  }

  public clearHasChange(key: string): void {
    if (this.hasChange[key] !== undefined) this.hasChange[key] = false;
  }

  public setAdmin(value: boolean) {
    UsersModule.updateUser({ id: this.id, userData: { isAdmin: value } });
  }

  public onNewPassword(password: string) {
    UsersModule.updatePassword({ userId: this.id, password });
  }

  public initializeFields() {
    this.models['status'] = this.user['status'] as string;
    this.validation['status'] = between(0, 100);
    for(const key in this.user.fields)
    {
      const currentField = this.user.fields[key]
      if(
        currentField.title === 'Дата рождения'  || 
        currentField.title === 'E-mail' || 
        currentField.title === 'Номер телефона' ||
        currentField.title === 'Ссылка на соцсеть' || 
        currentField.title === 'Город' ||  
        currentField.title === 'Хобби')
      {
        if(currentField.title === 'E-mail')
        {
          this.fields.push({name: key, type: InputType.Date, label: currentField, validator: and([required, email]), field: 'default', order: 1})
        }
        if(currentField.title === 'Номер телефона')
        {
          this.fields.push({name: key, type: InputType.Tel, label: currentField.title, validator: xor([empty, onlyDigits(between(4, 50))]), field: 'default', order: 2})
        }
        if(currentField.title === 'Дата рождения')
        {
          this.fields.push({name: key, type: InputType.Date, label: currentField.title, field: 'default', order: 3})
        }
        if(currentField.title === 'Хобби')
        {
          this.fields.push({name: key, type: InputType.Textarea, label: currentField.title, field: 'default', order: 6})
        }
        
        
        if(currentField.title === 'Город')
        {
          this.fields.push({name: key, type: InputType.Text, label: currentField.title, field: 'default', order: 4})
        }
        if(currentField.title === 'Ссылка на соцсеть')
        {
          this.fields.push({name: key, type: InputType.Text, label: currentField.title, field: 'default', order: 5})
        }
      }
      else
      {
        this.fields.push({name: key, type: InputType.Text, label: currentField.title, field: 'custom'})
      }
    }
    
    this.fields.forEach(field => {
      const name = field.name;
      this.models[name] = typeof this.user.fields[name] === 'object' ? (this.user.fields[name].value as string) : '';
      this.validation[name] = field.validator;
      this.clearHasChange(name);
    });
  }

  public getFieldByName(name: string): InputModel {
    return this.fields.find(field => field.name === name);
  }

  public setUserProp(key: string) {
    if (!this.validate(key)) return;
    if (!this.hasChange[key]) return;

    // UsersModule.updateUser({ id: this.id, userData: { [key]: this.models[key] } });

    this.clearHasChange(key);
  }

  // ~~~ Hooks ~~~


}
