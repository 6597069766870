


























































































































































import { mixins } from 'vue-class-component';
import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator';
import PopupDialogDefault from '../ui/popup-dialog/PopupDialogDefault.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
// import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue';
import Checkbox from '@/components/ui/checkbox/Checkbox.vue';
import ToggleSwitch from '@/components/ui/toggle-switch/ToggleSwitch.vue';
import UserFields from '@/mixins/UserFields';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import ContextMenuHeader from '@/components/context-menu/items/ContextMenuHeader.vue';
import ContextMenuToggle from '@/components/context-menu/items/ContextMenuToggle.vue';
import Button from '@/components/ui/button';
import Input from '@/components/ui/input';
import Notify from '@/services/helpers/notify';
import Icon from '@/components/ui/icon/Icon.vue';
import ApiClient from '@/api/api.client';
import UsersModule from '@/store/modules/users/UsersModule';
import TeamsModule from '@/store/modules/teams/TeamsModule';
import GeneralModule from '@/store/modules/general/GeneralModule';

@Component({
    components: {
        ContextMenuSpawner,
        ToggleSwitch,
        PopupDialogDefault,
        ContextMenuHeader,
        Icon,
        ContextMenuToggle,
        Button,
        Input,
        ContextMenuLink,
        Checkbox,
    },
})
export default class UserCard extends mixins(UserFields) {
    private password = '';

    @Ref('photoUpload') readonly photoUpload!: HTMLInputElement;
    @Ref('userOptionsMenu') readonly userOptionsMenu!: ContextMenuSpawner;
    @Prop({ default: false }) editable: boolean;
    @Prop({ default: true }) valid: boolean;
    public isActive = false;
    get roles() {
        return this.user.roles;
    }
    public userStatus = '';
    public showDeleteContext = false;
    public openSettingContext = false;
    public userCardKey = 1;
    public isUserAdmin = false;
    public userAvatarUrl = '';
    public passwordContextMenu = false;
    public base64avatar: FileList = null;
    
    checkboxChange(e: Event) {
        console.log(e.target);
    }
    openLink(url: string)
    {
        if (!url.startsWith('http')) {
            url = 'https://' + url;
        }
        window.open(url, '_blank', 'noopener noreferrer');
    }

    changePassword(newPassword: string) 
    {
        if(this.user.id === UsersModule.currentUser.id || UsersModule.currentUser.admin)
        {
            ApiClient.teams.changePassword(this.user.id, newPassword).then(() => 
            {
                this.passwordContextMenu = false;
                if(this.user.id === UsersModule.currentUser.id)
                {
                    GeneralModule.logout();
                    this.$router.push('/login')
                }
                Notify.success('Пароль изменен');
            })
        }
        else
        {
            Notify.error('Вы не являетесь администратором');
        }
    }
    get userHasAdminSpecialisations()
    {
        return this.user.specialisations.filter((el) => el.is_admin).length
    }
    get userFields()
    {   
        return Object.values(this.user.fields)
    }
    get city()
    {
        return this.user.fields?.city?.value 
    }
    get currentUser()
    {
        return UsersModule.currentUser
    }
    get hobby()
    {
        return this.user.fields?.hobby?.value
    }
    get phone()
    {
        return this.user.fields?.phone?.value
    }
    get soclink()
    {
        return this.user.fields?.soc_link?.value
    }
    removeAvatar() {
        this.models.avatar = null;
        this.user.avatar = null;
        this.userCardKey = Number(new Date());
        this.setUserProp('avatar');
    }
    closeUserCardMenu() {
        this.openSettingContext = false;
    }
    to(path: string) {
        this.$router.push(path);
    }
    uploadPhoto() {
        this.photoUpload.click();
    }
    openDropout() {
        this.isActive = this.isActive ? false : true;
    }
    isImageValid(type: string): boolean {
        const allowedTypes = ['/png', '/jpeg', '/webp', '/ico', '/gif'];
        let isAllowed = false;
        allowedTypes.forEach(el => {
            if (type.indexOf(el) >= 1) {
                isAllowed = true;
            }
        });
        return isAllowed;
    }
    deleteUser() {
        if (UsersModule.currentUser.admin) {
            this.showDeleteContext = false;
            ApiClient.user.deleteUser(this.user.id).then(() => {
                this.$router.push('/team')
                Notify.success('Пользователь удален');
            });
        } else {
            Notify.error('Вы не являетесь администратором');
        }
    }
    get shortBirthDay() {
        return this.user.fields?.birth_data?.value ? (this.user.fields.birth_data.value as string).slice(0, 10) : '';
    }

    onPasswordSubmit() {
        this.onNewPassword(this.password);
        this.password = '';
        this.userOptionsMenu.close();
    }
    async onPhotoUpload(e: Event & { target: EventTarget }) {
        const reader = new FileReader();
        const target = e.target as HTMLInputElement;
        if (!this.isImageValid(target.files[0].type)) {
            Notify.error('Данный формат не поддерживается');
            return;
        }
        reader.readAsDataURL(target.files[0]);
        reader.onload = () => {
            this.userAvatarUrl = URL.createObjectURL(target.files[0]);
            this.models.avatar = this.userAvatarUrl;
            this.setFieldValue('avatar', URL.createObjectURL(target.files[0]));
            this.setUserProp('avatar');
            this.clearHasChange('avatar');
            this.base64avatar = target.files;
            this.user.avatar = URL.createObjectURL(target.files[0]) as string;
            this.user.avatar = this.user.avatar.slice(5, this.user.avatar.length);
            // Хак. не знаю почему но рендер не работает
            this.userCardKey = Date.now();
        };
    }
    get fullName() {
        return `${this.user.first_name} ${this.user.last_name}`;
    }
    mounted() {
        if(!Object.keys(this.owner).length && this.isAuthCompleted)
        {
            UsersModule.fetchOwner();
        }
        this.photoUpload.addEventListener('change', this.onPhotoUpload);
        this.isUserAdmin = this.user.admin;
    }
    get isAuthCompleted()
    {
        return GeneralModule.isAuthCompleted;
    }
    beforeDestroy() {
        this.photoUpload?.removeEventListener('change', this.onPhotoUpload);
    }

    changeUserAdmin() {
        if (UsersModule.currentUser.admin) {
            this.isUserAdmin = !this.isUserAdmin;
            ApiClient.user.makeAdmin(this.user.id).then(() => {
                Notify.success('Роль пользователя изменена');
            });
        } else {
            Notify.error('Вы не являетесь администратором');
        }
    }
    get owner()
    {
        return UsersModule.ownerUser;
    }

    @Watch('base64avatar')
    @Emit('avatarLoaded')
    userAvatarUpdated() {
        return this.base64avatar;
    }
}
