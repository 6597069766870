import { IValidator } from '@/services/validators/model';

export interface ISelectorOption {
  text: string;
  value: string | number;
  count?: number;
}

export enum InputType {
  Text = 'text',
  Textarea = 'textarea',
  Password = 'password',
  Email = 'email',
  Search = 'search',
  Tel = 'tel',
  Url = 'url',
  Number = 'number',
  Range = 'range',
  Date = 'date',
  DateTime = 'datetime-local',
  Month = 'month',
  Time = 'time',
  Week = 'week',
  Color = 'color',
}

export interface InputModel {
  name: string;
  value?: string | number | boolean;
  type: InputType;
  order?: number;
  label?: string;
  field: string,
  validator?: IValidator;
}

export interface NamedItem {
  name: string;
  [key: string]: unknown;
}

export interface IdentifiedItem {
  id: number;
  [key: string]: unknown;
}
