import { IValidator } from '../model';
import AbstractConvertor from './AbstractConvertor';

export class OnlyDigits extends AbstractConvertor {
  VALIDATOR_NAME = 'onlyDigits';

  conversionFn(value: string) {
    return value.replace(/\D+/g, '');
  }
}

export default function(validator: IValidator, name?: string) {
  return new OnlyDigits(validator, name);
}
