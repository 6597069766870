import { IValidationResult, IValidator } from '../model';

export default abstract class AbstractConvertor implements IValidator {
  VALIDATOR_NAME = 'abstractConvertor';
  convertedValidator: IValidator;

  constructor(validator: IValidator, name?: string) {
    if (name) this.VALIDATOR_NAME = name;
    this.convertedValidator = validator;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected conversionFn(value: string): string {
    throw new Error('You must implement conversion logic in convertor class!');
  }

  validate(value: string): IValidationResult {
    const convertedValue = this.conversionFn(value);
    const result = this.convertedValidator.validate(convertedValue);

    return {
      isValid: result.isValid,
      validators: {
        [this.VALIDATOR_NAME]: result.isValid,
        ...result.validators,
      },
    };
  }
}
