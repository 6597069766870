











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UserData extends Vue {
  @Prop({ required: true }) header: string;
}
