









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ErrorMessage extends Vue {
  @Prop({ required: true }) title: string;
}
