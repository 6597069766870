import { Component, Vue } from 'vue-property-decorator';
import { IValidationResult, IValidator } from '@/services/validators/model';

export interface IValidationScheme {
  [key: string]: IValidator;
}

@Component
export default class Validators extends Vue {
  validation: IValidationScheme = {};
  validationResult: { [key: string]: IValidationResult } = {};
  models: { [key: string]: unknown } = {};

  isValid(key: string): boolean | null {
    if (!this.validationResult[key]) return null;
    return this.validationResult[key].isValid;
  }

  clearValidation(key: string) {
    if (!this.validationResult[key]) return;

    delete this.validationResult[key];
    this.reassignResults();
  }

  reassignResults() {
    this.validationResult = Object.assign({}, this.validationResult);
  }

  validate(key: string) {
    const value = this.models[key];
    const validator = this.validation[key];

    if (!validator) return true;

    this.validationResult[key] = validator.validate(value as string);
    this.reassignResults();

    return this.validationResult[key].isValid;
  }

  validateAll() {
    const keys = Object.keys(this.validation);

    keys.forEach(key => {
      this.validate(key);
    });
  }
}
